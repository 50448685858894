import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import ThreeColumnCards from "../components/BlogPostListing/ThreeColumnCards";
const _ = require("lodash");

class Listing extends React.Component {


  render() {
    const { exchangeName } = this.props.pageContext;
    const postEdges = this.props.data.allAirtable.edges;
    return (
      <Layout>
        <div className="category-container">
          <Helmet
            title={`${exchangeName}`}
          />
          <ThreeColumnCards postEdges={postEdges} title={exchangeName} />

        </div>    
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($exchangeName: String, $skip: Int!, $limit: Int!) {
    allAirtable(
      filter: { 
          data : { 
              exchangeName: { eq: $exchangeName } 
            } 
        }        
      limit: $limit
      skip: $skip  
      sort: { fields: [data___name], order: ASC }
    ) {
      edges {
        node {

                    data {
                        symbol
                        exchangeName
                        name
                        date
                        currency
                        c
                        o
                        t(formatString: "MMMM Do, YYYY hh:mma")
                        exchangeTitle
                    }

        }
      }
    }
  }
`;
