import React from "react";
import Img from "gatsby-image";
const config = require("../../../data/SiteConfig")
const _ = require("lodash");

class ThreeColumnCards extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
    const oldNumber = postEdge.node.data.o;
    const newNumber = postEdge.node.data.c;
    const changeValue = newNumber - oldNumber; 
    const changePercentage = (changeValue / oldNumber) * 100;
    const changePercentageDecimal = changePercentage.toFixed(2);        
      postList.push({
        path: `/${config.blogDirectory}/${_.kebabCase(postEdge.node.data.exchangeName)}/${_.kebabCase(postEdge.node.data.name)}/`,
        category: postEdge.node.data.exchangeName,
        title: postEdge.node.data.name,
        date: postEdge.node.data.date,
        symbol: postEdge.node.data.symbol,
        latest: postEdge.node.data.c,
        open: postEdge.node.data.o,
        changePercentageDecimal: changePercentageDecimal,    
        currency: postEdge.node.data.currency
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();

    return (
      <div>
           
        <div className="pt-6 bg-white overflow-hidden mb-8">
        <div className="relative mx-auto pl-4 pr-4">

            <nav class="flex items-center text-sm leading-5 font-medium pb-4">
            <a href="/" class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out">Share Prices</a>
            <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
            </svg>
            <div class="text-gray-400">{this.props.title}</div>
            </nav>      

            <div className="text-left">
            <h1 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                {this.props.title}
            </h1>
            <p className="mt-2 mx-auto text-xl leading-7 text-gray-500 sm:mt-4 mb-4">
                Latest share prices from {this.props.title} listed companies
            </p>
            </div>

            <div class="bg-white shadow overflow-hidden sm:rounded-md mb-2">
                    <div class="flex items-center px-4 py-4 sm:px-6 bg-gray-50">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-4">
                        <div>
                            <div class="text-md leading-5 font-medium text-gray-600 truncate">
                                Title
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-600">
                                Symbol
                            </div>
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-600">
                                Open
                            </div>
                            </div>
                        </div>   
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-600">
                                Latest
                            </div>
                            </div>
                        </div>               
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-600 pl-4">
                                Change
                            </div>
                            </div>
                        </div>                                                               
                        </div>
                    </div>
                    <div>
                        <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                        </svg>
                    </div>
                    </div>                
            <ul>


                {/* Your post list here. */
                
                postList.map(post => (

                <li key={post.symbol} class="border-t border-gray-200">
                <a href={post.path} class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-4">
                        <div>
                            <div class="text-md leading-5 font-bold text-teal-400 truncate">{post.title}
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-900">
                                {post.symbol}
                            </div>
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-900">
                                {new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: post.currency
                                }).format(post.open)}
                            </div>
                            </div>
                        </div>        
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-900">
                            <span>

                                {new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: post.currency
                                }).format(post.latest)}

                            </span>
                            </div>
                            </div>
                        </div>     
                        <div class="hidden md:block">
                            <div>
                            <div class="text-md leading-5 text-gray-900">
                            <span className="ml-3 text-xl leading-7 font-medium text-gray-500">
                                {post.changePercentageDecimal < 0 ? 
                                <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                                </svg>
                                {post.changePercentageDecimal}%
                                </div> 
                                    : 
                                <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                                </svg>
                                {post.changePercentageDecimal}%
                                </div>
                                }
                            </span>
                            </div>
                            </div>
                        </div>                                                                  
                        </div>
                    </div>
                    <div>
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                        </svg>
                    </div>
                    </div>
                </a>
                </li>
                        

                ))}

            </ul>
            </div>     
            </div>
        </div>
        </div>

        
    );
  }
}

export default ThreeColumnCards;
